import React, { useState, useEffect } from "react";
import SiteHeading from "./subcomponents/site_header";
import SiteFooter from "./subcomponents/site_footer";
import Bill from "../../assets/ip/freepik/Bill.jpg";
import Marketing from "../../assets/ip/freepik/Marketing.jpg";
import Website from "../../assets/ip/freepik/Website.jpg";
import Background from "../../assets/ip/freepik/Background.jpg";

// Define the Home component
function Services() {
  return (
    <div className="container-fluid" style={{ padding: 0,
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundPosition: "center", 
      minHeight: "100vh"
    }}>
      <SiteHeading />
      <div
        className="container"
        style={{
          padding: 0,
        }}
      >
        <div
          className="d-flex align-items-center flex-column"
        >
          <div
            className="card justify-content-center align-content-center flex-row"
            style={{
              maxHeight: "25vh",
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 0,
              margin: 0,
              border: "none"
            }}
          >
            <div className="card-body align-content-center" style={{boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",width:"50%", overflow:"auto"}}> 
              <h2>
                Website Building
              </h2>
              <div className="container" style={{width:"80%"}}>
                <h4>Our central offering is a hi-fidelity web-design service. Using a combination of modern frameworks and AI 
                  to speed up development, we'll provide a high quality website at a fraction of the cost</h4>
              </div>
            </div>
            <img className="card-img" src={Website} style={{width:"50%", overflow:"auto"}}></img>
          </div>
          <div
            className="card justify-content-center align-content-center flex-row"
            style={{
              maxHeight: "25vh",
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 0,
              margin: 0,
              border: "none"
            }}
          >
            <img className="card-img" src={Marketing} style={{width:"50%", overflow:"auto"}}></img>
            <div className="card-body align-content-center" style={{boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",width:"50%", overflow:"auto"}}> 
              <h2>
                Marketing Services
              </h2>
              <div className="container" style={{width:"80%"}}>
                <h4>In addition to websites, we also offer premium marketing packages. 
                  Ad Campaigns, SEO, Social Media, and more.
                </h4>
              </div>
            </div>
          </div>
          <div
            className="card justify-content-center align-content-center flex-row"
            style={{
              maxHeight: "30vh",
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 0,
              margin: 0,
              border: "none"
            }}
          >
            <div className="card-body align-content-center" style={{boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",width:"50%", overflow:"auto"}}> 
              <h2>
                Bookkeeping
              </h2>
              <div className="container" style={{width:"80%"}}>
                <h4>Our bookkeeping service makes TaskBridge a one-stop shop that allows you to keeping doing what you do best. 
                  Allow us to manage your cash flow so the unexpected can be expected </h4>
              </div>
            </div>
            <img className="card-img" src={Bill} style={{width:"50%", overflow:"auto"}}></img>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default Services;
