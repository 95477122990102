import React, { useState, useEffect } from "react";
import SiteHeading from "./subcomponents/site_header";
import SiteFooter from "./subcomponents/site_footer";
import Background from "../../assets/ip/freepik/Background.jpg";
import useWindowSize from "../hooks/useWindowSize";
import "holderjs";
import Collaboration from "../../assets/ip/freepik/Collaboration.jpg";
import Technology from "../../assets/ip/freepik/Technology.jpg";
import Carousel from "react-bootstrap/Carousel";
//import ExampleCarouselImage from "components/ExampleCarouselImage";

const HomeOverlay = () => {
  return(
    <>
      <div
        className="card"
        style={{
          maxHeight: "30vw",
          padding: 0,
          margin: 0,
        }}
      >
        <img className="img-card" src={Technology} alt="Background" style={{objectFit:"cover", overflow:"auto"}} />
        <div className="card-img-overlay d-flex justify-content-center align-content-center flex-column" style={{padding:0,margin:0, overflow:"auto"}}>
          <div className="d-flex justify-content-between flex-row" style={{padding:0, margin:0}}>
            <div className="d-flex text-center justify-content-center align-items-center" style={{width:"20%", height:"10vw", padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
              <p >How We Are Better</p>
            </div>
            <div className="d-flex" style={{width:"20%", height:"10vw", padding:0, margin:0, opacity:0}}/>
            <div className="d-flex text-center justify-content-center align-items-center" style={{width:"20%", height:"10vw", padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
              <p>Tiered, Affordable Pricing Structure</p>
            </div>
            <div className="d-flex" style={{width:"20%", height:"10vw", padding:0, margin:0, opacity:0}}/>
            <div className="d-flex text-center justify-content-center align-items-center" style={{width:"20%", height:"10vw", padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
              <p>Custom Tailored to Your Needs</p>
            </div>
          </div>
          <div className="d-flex justify-content-around flex-row" style={{padding:0, margin:0}}>
            <div className="d-flex" style={{width:"20%", height:"10vw", padding:0, margin:0, opacity:0}}/>
            <div className="d-flex text-center justify-content-center align-items-center" style={{width:"20%", height:"10vw", padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
              <p>Empowering Local Businesses</p>
            </div>
            <div className="d-flex" style={{width:"20%", height:"10vw", padding:0, margin:0, opacity:0}}/>
            <div className="d-flex text-center justify-content-center align-items-center" style={{width:"20%", height:"10vw", padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)", boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
              <p>Dedicated, Qualified Candidates</p>
            </div>
            <div className="d-flex" style={{width:"20%", height:"10vw", padding:0, margin:0, opacity:0}}/>
          </div>
        </div>
      </div>
    </>
  );
}

const HomeCarousel = () => {
  return(
    <div
        className="card"
        style={{
          maxHeight: "30vh",
          padding: 0,
          margin: 0,
        }}
      >
        <img className="img-card" src={Technology} alt="Background" style={{objectFit:"cover", overflow:"auto"}} />
        <div className="card-img-overlay d-flex justify-content-center align-items-center flex-column" style={{padding:0,margin:0, }}>
          <div className="container text-center align-content-center" style={{height:"20vh", width:"85%", padding:0,margin:0,}}>
            <Carousel>
              <Carousel.Item>
                {/* <img className="d-block w-100" src={Technology } alt="First slide" /> */}
                <div className="container align-content-center flex-fill" style={{height:"20vh",padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
                  <p>Tiered, Affordable Pricing Structure</p>
                </div>
                {/* <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                {/* <img className="d-block w-100" src={Technology } alt="First slide" /> */}
                <div className="container align-content-center flex-fill" style={{height:"20vh",padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
                  <p>Custom Tailored Solutions</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="container align-content-center flex-fill" style={{height:"20vh",padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
                  <p>Empowering Local Businesses</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="container align-content-center flex-fill" style={{height:"20vh",padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
                  <p>Dedicated & Qualified Candidates</p>
                </div>
              </Carousel.Item>
              {/* <Carousel.Item>
                <div className="container align-content-center flex-fill" style={{height:"25vw",padding:0, margin:0, backgroundColor:"rgba(255,255,255,0.75)",boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",overflow:"auto"}}>
                  <p >How We Are Better</p>
                </div>
              </Carousel.Item> */}
            </Carousel>
          </div>
        </div>
    </div>
  );
}

// Define the Home component
function Home() {
  const size = useWindowSize();

  return (
    <div className="container-fluid" style={{padding: 0,
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundPosition: "center", 
      minHeight: "100vh"
      }}>
      <SiteHeading />
      <div
        className="container"
        style={{
          padding: 0
        }}
      >
        {/* New Component */}
        <div
          className="container"
          style={{
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.75)",
            padding: 0,
            boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",
            overflow: "auto",
          }}
          
        >
          <div className="mt-3"/>
          <div
            className="container align-content-center"
            style={{
              maxWidth: "400px",
              maxHeight: "40vw",
              textAlign: "center",
              padding: 0,
            }}
          >
            <p>Not just another consulting firm</p>
            <p>
              At TaskBridge Solutions, we are committed to providing the best
              service to our clients. We are a full-service consulting firm
              that specializes in IT, Marketing, and Bookkeeping to provide an
              all-in-one business development solution that can be tailored to
              your needs.
            </p>
          </div>
        </div>
        {/* New Component */}
        {size.width >= 900 ? <HomeOverlay /> : <HomeCarousel/>}
        {/* <HomeCarousel/> */}
        {/* New Component */}
        <div
          className="card justify-content-center align-content-center flex-row"
          style={{
            maxHeight: "40vh",
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.75)",
            padding: 0,
            margin: 0,
            border: "none",
          }}
        >
          <div className="card-body align-content-center" style={{overflow: "auto", width:"50%", boxShadow: "inset 0px 0px 3px rgba(0,0,0,0.5)",}}> 
            <p>
            We Are Always Growing! <br/ >Contact Us To Learn More 
            </p>
          </div>
          <img className="card-img" src={Collaboration} style={{width:"50%", overflow:"auto"}}></img>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default Home;
