import React from "react";
import { MdOutlineFacebook} from "react-icons/md";
import { FaLinkedin, FaSquareInstagram } from "react-icons/fa6";

function SiteFooter() {
  // Define the SiteFooter component
  return (
    <footer className="container-fluid"
      style={{ 
      // backgroundImage: `url(${Background})`,
      // backgroundSize: "cover",
      // backgroundPosition: "center",
      padding: 10,
      margin: 0,
      backgroundColor: "rgba(255,255,255,0.75)",
      width: "100%",
    }}
    >
      <div className="container d-flex flex-row" >
          <div className="container justify-content-end d-flex" style={{overflow:"auto", }}>
            <p>&copy; TaskBridge Solutions</p>
          </div>
          <div className="container d-flex justify-content-center flex-row" style={{overflow:"auto", }}>
            <MdOutlineFacebook size={28} />
            <div className="mx-2"></div>
            <FaLinkedin size={28} />
            <div className="mx-2"></div>
            <FaSquareInstagram size={28} />
          </div>
          <div className="container d-flex" style={{overflow:"auto", }}>
            <p>Email Support: <br/>msfreelancesolutions@gmail.com</p>
          </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
