import React from "react";
import SiteHeading from "./subcomponents/site_header";
import SiteFooter from "./subcomponents/site_footer";
import Background from "../../assets/ip/freepik/Background.jpg";

// Define the Contact component
// Should contain a form for the user to enter their contact information and a message box and submit button
function Contact() {
  return (
    <div className="container-fluid" style={{ margin: 0, padding: 0,backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundPosition: "center", }}>
      <SiteHeading />
      <div
            className="container"
            style={{
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 0,
            }}
          >
            <div className="my-4"/>
            <div className="container" style={{width:"400px", textAlign: "center", padding: 20, backgroundColor: "rgba(216,216,216,0.75)", borderRadius: 20 }}>
              <form>
                  <h1>Contact Us</h1>
                  <div className="form-group text-start">
                      <label for="name" style={{fontSize:"1.5rem"}}>Name:</label>
                      <input type="text" className="form-control" id="name" placeholder="Enter your name" required/>
                  </div>
                  <div className="my-3"/>
                  <div className="form-group text-start">
                      <label for="email" style={{fontSize:"1.5rem"}}>Email:</label>
                      <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
                  </div>
                  <div className="my-3"/>
                  <div className="form-group text-start">
                      <label for="message" style={{fontSize:"1.5rem"}}>Message:</label>
                      <textarea className="form-control" id="message" placeholder="Enter your message" rows="5" required/>
                  </div>
                  <div className="my-3"/>
                  <button type="submit" className="btn btn-primary" style={{width:"100%"}}>Submit</button>
              </form>
            </div>
        </div>
      <SiteFooter />
    </div>
  );
}

export default Contact;
