import React from "react";
import Logo from "../../../assets/bridge-logo4.png"
import useWindowSize from "../../hooks/useWindowSize";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";

const NavBarLarge = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button variant="primary" href="/">
        Home
      </Button>
      <div className="mx-3"></div>
      <Button variant="primary" href="/services">
        Services
      </Button>
      <div className="mx-3"></div>
      <Button variant="primary" href="/contact">
        Contact
      </Button>
      <div className="mx-3"></div>
      <Button variant="primary" href="/about">
        About
      </Button>
      <div className="mx-3"></div>
      <Button variant="primary" href="/login">
        Login
      </Button>
    </div>
  );
}
const NavBarSmall = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button variant="primary" href="/">
        Home
      </Button>
      <div className="mx-3"></div>
      <Button variant="primary" href="/services">
        Services
      </Button>
      <div className="mx-3"></div>
      <DropdownButton color="primary" id="dropdown-basic-button" title="Menu">
        <Dropdown.Item href="/contact">Contact</Dropdown.Item>
        <Dropdown.Item href="/about">About</Dropdown.Item>
        <Dropdown.Item href="/login">Login</Dropdown.Item>
      </DropdownButton>
    </div>
  );
}

function SiteHeading() {
  // Define the SiteHeading component
  const size = useWindowSize();

  return (
    <div
      className="container-fluid"
      style={{backgroundColor: "rgba(255,255,255,0.75)", width: "100%", padding:0}}
    >
      <div className="my-2"> </div>
      <div className="container" style={{backgroundColor: "rgba(255,255,255)"}}>
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center">
            <img src={Logo} className="icon" alt="TaskBridge Solutions" style={{width:"100px"}}/>
          </div>
            {size.width > 500 ? <NavBarLarge /> : <NavBarSmall />}
            <div className="my-3"/>
        </div>
      </div>
      <div className="shadow" style={{ backgroundColor: "lightgrey" }}>
        <div className="container">
          <div className="d-flex justify-content-end">
            Call Us! 1-800-TBSOLS
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteHeading;
