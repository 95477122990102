import React from "react";
import SiteHeading from "./subcomponents/site_header";
import SiteFooter from "./subcomponents/site_footer";
import Background from "../../assets/ip/freepik/Background.jpg";

// Define the About component
// Should contain a brief description of the company and its mission and FAQs
function About() {
  return (
    <div className="container-fluid" style={{ padding: 0, 
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundPosition: "center", 
      minHeight: "100vh" }}>
      <SiteHeading />
      <div
            className="container"
            style={{
              textAlign: "center",
              backgroundColor: "rgba(255,255,255,0.75)",
              padding: 0,
            }}
          >
            <br/>
            <strong>About TaskBridge Solutions</strong>
            <div
              className="container"
              style={{
                maxWidth: "400px",
                // maxHeight: "20vw",
                textAlign: "center",
                padding: 15,
                backgroundColor: "rgba(216,216,216,0.75)",
                borderRadius: 20,
                overflow: "auto",
              }}
            >
              <p>
                TaskBridge Solutions is a consulting firm that specializes in
                providing solutions for small businesses and entrepreneurs. We
                offer a wide range of services, including website development,
                marketing, and business consulting. Our mission is to help our
                clients achieve their goals and grow their businesses. We are
                committed to providing high-quality services and building
                long-term relationships with our clients. Contact us today to
                learn more about how we can help you succeed.
              </p>
            </div>
            <div className="my-5"/>
            <strong>Our Mission</strong>
            <div
              className="container"
              style={{
                maxWidth: "400px",
                // maxHeight: "30vw",
                textAlign: "center",
                padding: 10,
                backgroundColor: "rgba(216,216,216,0.75)",
                borderRadius: 20,
                overflow: "auto",
              }}
            >
              <p>
                Our mission is to provide high-quality consulting services to
                small businesses and entrepreneurs. We are committed to helping
                our clients achieve their goals and grow their businesses. We
                strive to build long-term relationships with our clients and
                provide them with the support they need to succeed. Contact us
                today to learn more about how we can help you achieve your
                business goals.
              </p>
            </div>
            <div className="my-5"/>
            <strong>FAQs</strong>
            <div className="container align-self-center text-start" id="accordion" role="tablist" style={{maxWidth: "600px",}}>
              <div class="card">
                <div class="card-header" role="tab" id="headingOne">
                  <h5 class="mb-0">
                    <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What services do you offer?
                    </ a>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                  <div class="card-body">
                    A: We offer a wide range of services, including website development, marketing, and business consulting. Contact us today to learn more about how we can help you grow your business.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" role="tab" id="headingTwo">
                  <h5 class="mb-0">
                    <a class="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      How can I get started?
                    </a>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse show" role="tabpanel" aria-labelledby="headingTwo">
                  <div class="card-body">
                    A: Contact us today to schedule a consultation and learn more about how we can help you achieve your business goals.
                  </div>
                </div>
              </div>
            </div>
        </div>
      <SiteFooter />
    </div>
  );
}

export default About;
